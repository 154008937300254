<template>
  <div class="PersonalInfo">
    <div class="PeopleCenter_h">
      <div class="Avatar_box">
        <div class="Avatar">头像</div>
        <div>
          <van-image fit="cover" :src="avatarImg" />
        </div>
        <div class="uploader">
          <van-uploader :after-read="afterRead" :deletable="false" />
        </div>
      </div>
      <div class="enter">
        <van-field
          v-model="name"
          ref="nameInp"
          label="姓名"
          placeholder=""
          @blur="inputBlur"
        />
        <van-icon name="arrow" @click="$refs.nameInp.focus()" />
      </div>
      <div class="enter">
        <van-field v-model="sex" label="性别" readonly @click="show = true" />
        <van-icon name="arrow" @click="show = true" />
        <van-action-sheet
          v-model="show"
          :actions="actions"
          @select="onSelect"
        />
      </div>
      <div class="enter">
        <van-field
          v-model="tel"
          label="手机号"
          type="tel"
          placeholder=""
          @blur="inputBlur"
          ref="telInp"
        />
        <van-icon name="arrow" @click="$refs.telInp.focus()" />
      </div>
      <div class="enter">
        <van-field
          v-model="company"
          label="我的公司"
          placeholder=""
          @blur="inputBlur"
          ref="companyInp"
        />
        <van-icon name="arrow" @click="$refs.companyInp.focus()" />
      </div>
      <div class="sign_out" v-if="isShowOut">
        <van-button round type="danger" @click="signOutClick" size="normal"
          >退出登录</van-button
        >
      </div>
    </div>

    <copyrightIp />
  </div>
</template>
<script>
import {
  Field,
  Button,
  ActionSheet,
  Uploader,
  Image as VanImage,
  Icon,
  Notify,
} from "vant";
import { updateUserInfo, findUserByOpenId } from "@/api/PeopleCenter";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [ActionSheet.name]: ActionSheet,
    [Uploader.name]: Uploader,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Notify.name]: Notify,
    copyrightIp,
  },
  data() {
    return {
      name: "",
      sex: "",
      tel: "",
      company: "",
      show: false,
      actions: [
        { name: "男", sex: "0" },
        { name: "女", sex: "1" },
      ],
      sexType: "",
      avatarImg: "",
      multipartFile: "",
      isShowOut: false,
    };
  },
  mounted() {
    this.isShowOut = this.$route.query.type || false;
    let openId = localStorage.getItem("code") || "oE1PV5krMUmcVe6GLqHcKX8s-Tj8";
    findUserByOpenId({ openId }).then((res) => {
      this.name = res.data.data.wxNickname || "";
      this.sexType = res.data.data.wxSex || 0;
      this.sex = this.sexType == 0 ? "男" : "女";
      this.tel = res.data.data.userPhone || "";
      this.company = res.data.data.userCompany || "";
      this.avatarImg =
        res.data.data.wxHeadimgurl || require("@/assets/image/main.jpg");
      this.multipartFile = this.avatarImg;
    });
  },
  methods: {
    signOutClick() {
      localStorage.clear();
      this.$router.push("/login");
    },
    getUpdateUserInfo() {
      if (this.name) {
        let openId =
          localStorage.getItem("code") || "oE1PV5krMUmcVe6GLqHcKX8s-Tj8";
        let formdata = new FormData();
        formdata.append("userName", this.name);
        formdata.append("sex", this.sexType);
        formdata.append("phone", this.tel);
        formdata.append("openId", openId);
        formdata.append("multipartFile", this.multipartFile);
        formdata.append("company", this.company);
        updateUserInfo(formdata).then((res) => {});
      } else {
        Notify("姓名不能为空！！");
      }
    },

    afterRead(file) {
      this.avatarImg = file.content;
      this.multipartFile = file.file;
      // 此时可以自行将文件上传至服务器

      this.getUpdateUserInfo();
    },
    inputBlur() {
      this.getUpdateUserInfo();
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起

      this.sex = item.name;
      this.sexType = item.sex;
      this.show = false;
      this.getUpdateUserInfo();
    },
  },
};
</script>
<style lang="scss" scoped>
.PersonalInfo {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #fff;
  .PeopleCenter_h {
    min-height: calc(100% - 44px);
  }
  .Avatar_box {
    width: 100%;
    font-size: 15px;
    // font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee9e9;
    padding: 10px 16px;
    margin-bottom: 10px;
    position: relative;

    .Avatar {
      font-weight: bold;
    }
    .uploader {
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      ::v-deep .van-uploader__upload {
        margin: 0;
      }
      ::v-deep .van-uploader__preview {
        margin: 0;
        .van-image {
          width: 62px;
          height: 62px;
          overflow: hidden;
          border-radius: 8px;
        }
      }
    }

    .van-image {
      width: 62px;
      height: 62px;
      border-radius: 10px;
      overflow: hidden;
    }
  }
  .enter {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    border-bottom: 1px solid #eee9e9;
    padding: 3px 0;
    .van-icon {
      color: #b6b6b6;
    }
    &:nth-last-child(1) {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    ::v-deep .van-cell {
      background: #f5f5f9;
      // font-weight: bold;
      font-size: 15px;
      background: #fff;
      &::after {
        border-bottom: 0;
      }
      input {
        text-align: end;
        color: #b0b1b2;
      }
      .van-cell__title {
        font-weight: bold;
      }
    }
  }
  .save {
    text-align: center;
    padding-top: 118px;
    ::v-deep .van-button {
      width: 40%;
    }
  }
  .sign_out {
    padding: 38px 0 15px;
    .van-button {
      width: 100%;
    }
  }
}
</style>
